import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

@Injectable()
export class VideoService {
  constructor(private http: HttpClient) {}

  public fetchAndCacheVideo(videoUrl: string): Observable<string> {
    return this.http.get(videoUrl, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const url = URL.createObjectURL(blob);
        if ('caches' in window) {
          caches.open('video-cache').then((cache) => {
            cache.put(videoUrl, new Response(blob)).catch((error) => {
              console.error('Error caching video:', error);
            });
          });
        }
        return url;
      })
    );
  }
}
